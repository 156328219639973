/* .css-13cymwt-control  { */
    /*This css for react select height increase */
    /* height: 30px;
  }
  .css-1fdsijx-ValueContainer{
    height: 30px;
  } */

  .fixed-toolbar {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure the toolbar is above the content */
    background: white; /* Match the background color */
    padding-top: 10px; /* Adjust as needed */
    padding-bottom: 10px; /* Adjust as needed */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  }
  
  .editor-content {
    
    min-height: 300px; /* Ensure the editor has a minimum height */
  }
  