.venturesathi_logo {
  width: 25%;
  height: auto;
}

.loginwelcometext {
  font-size: 2rem;
  font-weight: 600;
}

.productname {
  text-align: center;
  font-size: 1.8rem;
  margin: 0.5rem 0;
}

.logintext {
  color: var(--common-color);
}

.commonBtn {
  background-color: var(--commonBtn-color);
  color: white;
  cursor: pointer;
  padding: 0.6rem 0rem;
  font-weight: 300;
}

/* .loadingButton{
  background-color: var(--eyeicon-color) !important;
  color: red !important;
} */

.eyeicon {
  cursor: pointer;
  color: var(--eyeicon-color);
}

.linktext {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--link-color);
}

/* header css start */
.headerContainer {
  background-color: transparent;
  color: var(--header-text-color);
}

.logocontainer {
  display: flex;
  margin: auto;
  width: 9%;
}

.linkpages {
  position: relative;
  font-size: 1rem;
  color: var(--icon-color);
  padding: 0.4rem 0rem 0rem 0rem;
}

.linkpages::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 2rem;
  width: 0;
  height: 0.2rem;
  background-color: var(--header-text-color) !important;
  transition: width 0.4s linear;
}

.linkpages:hover::after {
  width: 100%;
}

.active {
  border-bottom: 0.2rem solid var(--header-text-color);
  color: var(--header-text-color);
}

.headericons {
  color: var(--icon-color);
}



.landingpage_logo {
  width: 100%;
  height: auto;
  padding: 0.5rem;
  cursor: pointer;
}

.userprofile {
  margin: auto 0;
  text-align: center;
  color: var(--btmbox-shadow);
}



/* header css end */

/* Navmenu css start */
.menu {
  display: flex;
  gap: 0.4rem;
}

.menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .menu .menu_item.active .link,
.menu .icon.activemenu,
.menu_container .active,
.menu_container .active .icon {
  color: var(--header-text-color);
} */

/* .activemenu {
  color: var(--icon-color);
} */

.link_text {
  white-space: nowrap;
  font-size: 0.9rem;
  padding: auto auto;
}

.icon {
  font-size: 1.2rem;
  padding-top: 0.5rem;
  color: var(--icon-color);
}


.menu_container {
  /* width: 100vw; */
  /* padding: 0rem 5rem !important; */
  display: flex;
  /* align-items: center; */
  /* text-align: center; */
  position: absolute;
  top: 6.5rem;
  background-color: transparent;
  color: var(--header-text-color);
  /* border-top:1px solid var(--icon-hover-color) ; */
  /* border-bottom: 1px solid var(--icon-hover-color); */
  /* box-shadow: 1px 1x 0.5px 1px var(--icon-hover-color); */
}


.link {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--icon-color);
  gap: 0.5rem;
  padding: 4.5px 10px;
  text-decoration: none;
  /* text-transform: uppercase; */
  font-weight: 500;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0.2rem;
  background-color: var(--header-text-color) !important;
  transition: width 0.4s linear;
}

.link:hover::after {
  width: 100%;
}

/* Navmenu css end */

/* All page css start */
.main{
  margin-top: 4rem;
}
/* All page css end */

/* profile css start */
.profileImage {
  width: 7vw;
  height: auto;
  /* padding: 0.5rem; */
}

.user-container {
  display: flex;
  gap: 1.2rem;
  margin: 0.5rem 0;
}

.labelname {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.labelvalue {
  font-size: 1rem;
  font-weight: 500;
}

/* profile css end */

/* dashboard css start */
.sectionContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.sectionHeading {
  font-size: 1.3rem;
  font-weight: 600;

}

.sectionNavigation {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--viewall-color);
}

.card-main-container {
  flex-wrap: wrap;
 gap: 1.2%;
  align-items: center;
}

.card {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  /* border: 2px solid red; */
}

.card-details {
  padding: 0.1rem 0.7rem;
}

.cardimg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 40%;
}

.card-title-container {
  display: flex;
  justify-content: space-between;

}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.05rem;
}

.card-title-icon {
  font-size: 1.2rem;
  display: flex;
  /* gap: 0.4rem; */
  justify-content: space-between;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-top: "3px";
}

.card-username {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--card-name-color);
  margin-bottom: 5px;
  /* margin-top: -3px; */

}

.cardtimeduration {
  display: inline;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 1rem;
  background-color: var(--card-name-color);
  color: var(--card-time-color);
}

.card-details-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.card-time {
  padding: 0.5rem 0rem;
  font-size: 1rem;
}

.cardchapter {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.videoicon {
  background-color: var(--header-color);
  color: var(--header-text-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* padding: 0.4rem; */
}

.acheivement-card {
  /* width: 50%;
  height: 50%; */
  background-image: url("../assets/dummycardimages.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 1rem;
  padding: 4rem 0rem;
}

/* dashboard css end */




/* HRMS css for navbar  */





/* -------  */


.cursor-pointer {
  cursor: pointer !important;
}

/* ul{
    box-shadow: none;
    padding: 0;
} */

.span-color {
  color: var(--common-color);
}

.heading {
  font-size: 1.8rem;
  font-weight: 400;
}

.commonBtn {
  background-color: var(--commonBtn-color);
  color: white;
  cursor: pointer;
  padding: 0.6rem 0rem;
  font-weight: 300;
}
.searchbar{
   padding: 0px !important;
}

.addBtn{
  background-color: var(--commonBtn-color);
  color: white;
  cursor: pointer;
  padding: 2rem 4rem;
  font-weight: 300;
}

.loginfields {
  width: 80%;
  margin: 10px 0px;
}

.colored-heading {
  color: var(--header-color) !important;
  font-size: 2rem;
  font-weight: 500;
}


.saveBtn{
  background-color: var(--savebtn-color);
  padding: 5px 20px;
  color: white;
}

.saveBtn2{
  background-color: var(--commonBtn-color);
  padding: 5px 20px;
  color: white;
}


.saveBtn:hover{
  background-color: var(--common-color);
}

.cancelBtn{
  padding: 5px 20px;
  background-color: white;
  color: var(--commonBtn-color);
}

.cancelBtn2{
  padding: 5px 20px;
  background-color: white;
  color: var(--commonBtn-color);
  border: 1px solid var(--commonBtn-color);

}

.formInputs input,
.formInputs .selectInput {
  padding: 10px 15px !important;
}

.form-label{
  font-weight: 500;
  margin: 0.3rem 0rem;

}

.second-nav {
  /* width: 100%; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 18px;
  padding: 12px 15px 16px; */
  box-shadow: var(--btmbox-shadow);
  /* font-size: 4rem; */
  
}
.second-nav1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
  padding: 4px 15px 2px;
  box-shadow: var(--btmbox-shadow);
  /* font-size: 4rem; */
  
}


.second-nav-btn{
  color: grey;
  font-size: 1rem;
  background-color: #FBFBFB;
  border: #F2F4F7 solid 1px;
}
.second-nav-btn1{
  color: #98A2B3;
  font-size: 1rem;
  background-color: #FBFBFB;
  border: #F2F4F7 solid 1px;

}

.secondnav-active-btn{
  color: #ffff;
  box-shadow: rgba(28, 57, 77, 0.158) 0px 10px 10px -4px;
  /* padding: 6px 12px 6px 12px; */
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #37668F;
}


.secondnav-active-btn1{
  color: var(--commonBtn-color);
  box-shadow: rgba(28, 57, 77, 0.158) 0px 10px 10px -8px;
  padding: 6px 12px 6px 12px;
  font-size: 1.1rem;
}

.third-nav{
  background-color:#F9FAFB;
  margin-bottom: 10px;
}

.drawer-heading{
  font-size: 1.5rem;
  padding: 0.7rem 1.2rem;
}

.view-page-title{
  font-size: 1.2rem;
  font-weight: 400;
}

.view-page-value{
  font-size: 1rem;
  font-weight: 400;
}

.commonShadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}