/* .custom-header {
  background-color: #37668f;
  color: aliceblue;
} */

.custom-icon {
  color: white; /* Icon color */
}
.module-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

/* .MuiDataGrid-columnHeader {
  background-color: #37668f;
} */
.MuiSvgIcon-root {
  color: grey;
}

/* for add module drawer */
.css-13cymwt-control  {
  /*This css for react select height increase */
  height: 46px;
}
.css-1fdsijx-ValueContainer{
  height: 46px;
}

/* .MuiButtonBase-root{
    color: white;
  }  
  .MuiIconButton-root {
    color: white;
  }
  .MuiIconButton-sizeSmall {
    color: white;
  }
  .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{
    color: white;
  }


  .MuiTypography-root {color: black;}
  .MuiTypography-body1 {color: black;}
 .MuiListItemText-primary {color: black;} */
/* .css-10hburv-MuiTypography-root{color: black;} */
