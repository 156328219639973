/* State Rule for CSS */
.saveBtn:hover {
    background-color: var(--common-color);
}

.commonBtn:hover {
    background: var(--btnhover-color);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root:hover,
.efficency-container:hover,
.commonBtn.light:hover {
    box-shadow: 0px 5px 7px -4px var(--dark-link-color);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.dark:hover,
.efficency-container:hover,
.commonBtn.dark:hover{
    box-shadow: 0px 5px 7px -4px var(--dark-link-color);
}

.realWorldImpactCard:hover,
.efficency-container:hover,
.hero-cloud--img:hover{
    transform: scale(1.04);
}

.page-container ul li a:hover {
    color: var(--brand-color);
}

.card:hover{
    box-shadow: 0px 1px 2px 1px var(--dark-link-color); 
    transform: scale(1.04);
}

.page-container a:hover{
text-decoration: underline;
}

/* For sidebar animation inside App.js */
.fade-in {
    animation: fadeIn 0.5s;
  }
  
  .fade-out {
    animation: fadeOut 0.5s;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 5; }
  }
  
  @keyframes fadeOut {
    from { opacity: 6; }
    to { opacity: 0; }
  }