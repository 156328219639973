h1.sopTitle {
    color: #fff;
    width: 100%;
    font-size: 18px;
    margin: 15px auto;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #003866;
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: capitalize;
  }
  
  h1.sopTitle span {
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    padding: 0px;
    border-radius: 4px;
    margin-left: 0px;
    text-transform: capitalize;
  }
  
  .completeSopEditor {
    position: relative;
  }
  
  .completeSopEditor h2 {
    font-size: 20px;
    font-weight: 600;
    padding-left: 12px;
    margin-bottom: 15px;
  }
  
  .editorBox {
    width: 100%;
    display: inline-block;
    background: #ffff;
    box-shadow: 0px 0px 5px #333;
    border: none;
    padding: 15px 10px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .buttonSop {
    width: 98%;
    margin: 0 auto;
    position: relative;
    margin-top: 20px;
  }
  
  .buttonAddNewSop {
    color: #003866;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    background: transparent;
    border: 1px solid #003866;
    border-radius: 5px;
    width: 110px;
    padding: 5px 10px;
    text-transform: capitalize;
    box-shadow: 0px 1px 5px #000;
    transition: all ease .5s;
  }
  
  .buttonAddNewSop:hover {
    color: #fff;
    background: #003866;
  }
  
  .buttonDraftNewSop {
    color: #cc5c00;
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    border: 1px solid #cc5c00;
    border-radius: 5px;
    width: 110px;
    letter-spacing: 0.5px;
    margin-right: 20px;
    padding: 5px 10px;
    box-shadow: 0px 1px 5px #000;
    transition: all ease .5s;
  }
  
  .buttonDraftNewSop:hover {
    color: #fff;
    background: #cc5c00;
  }
  
  .buttonSubmitNewSop {
    color: #006223;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    background: transparent;
    border: 1px solid #006223;
    border-radius: 5px;
    width: 110px;
    padding: 5px 10px;
    text-transform: capitalize;
    box-shadow: 0px 1px 5px #000;
    transition: all ease .5s;
  }
  
  .buttonSubmitNewSop:hover {
    color: #fff;
    background: #006223;
  }
  
  .buttonDelete {
    color: #800000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    background: transparent;
    border: 1px solid #800000;
    border-radius: 5px;
    width: 110px;
    padding: 5px 10px;
    margin-left: 15px;
    text-transform: capitalize;
    box-shadow: 0px 1px 5px #000;
    transition: all ease .5s;
  }
  
  .buttonDelete:hover {
    color: #fff;
    background: #800000;
  }
  
  .buttonCloseSop {
    width: 28px;
    font-size: 21px;
    height: 28px;
    background: white;
    color: red;
    top: 28px;
    position: absolute;
    right: 1px;
    border-radius: 50%;
    border: 0px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: 0px 0px 5px #000;
    padding: 12px;
  }
  
  
  .public-DraftStyleDefault-ltr {
    cursor: text;
  }
  
  .public-DraftStyleDefault-block {
    margin: 0px;
    line-height: 27px;
  }
  
  .completeSopEditor input[type="text"] {
    max-width: 400px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #999999;
    outline: none;
  }
  
  .completeSopEditor input[type="number"] {
    width: 100px;
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #999999;
    outline: none;
    margin-left: 15px;
  }
  
  .headerInput {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
  }
  
  .draftSubmit {
    margin: 25px auto;
    text-align: right;
    width: 98%;
  }
  
  .checkbox span {
    margin-left: 15px;
  }
  
  .checkbox input[type='checkbox'] {
    width: 16px;
    height: 16px;
    margin: 7px;
    border: 1px solid #000;
  }
  
  .rdw-option-wrapper {
    border: none;
  }
  
  .rdw-editor-wrapper {
    padding: 0px;
  }
  
  
  .sopListingView {
    /* background: #fff; */
    padding: 0px;
    border-radius: 10px;
    margin-bottom: 25px;
    overflow: hidden;
  }
  
  .table-responsive table tbody tr:last-child {
    border-bottom: 2px solid #d7d7d7;
  }
  
  .questionHeading {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #d7d7d7;
    background: #ffff;
    padding: 10px;
  }
  
  .questionHeading h4,
  .questionHeading p {
    margin: 0px;
    padding: 0px;
  }
  
  .questionHeading h4 {
    max-width: 85%;
    width: 100%;
  }
  
  .commentSection div {
    margin-bottom: 25px;
    text-align: right;
  }
  
  .commentSection div input {
    width: 100%;
    height: 75px;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
    outline: none;
  }
  
  .fullWidth {
    width: auto !important;
  }
  
  .editableTopic {
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
  }
  
  .DraftEditor-root {
    min-height: 160px;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    padding-left: 10px;
    background: #ffff;
  }
  
  .draftListing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #6e6e6e;
    margin-bottom: 10px;
  }
  
  .draftListing h5 {
    cursor: pointer;
    margin: 0;
    text-transform: capitalize;
    font-size: 20px;
  }
  
  a.pdfLinks {
    color: #003866;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  
  .faqContent {
    padding: 0px;
    border-radius: 7px;
    box-shadow: 0px 0px 5px #6e6e6e;
  }